@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
}

#root {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

main {
  flex: 2;
}

.toggle-path {
    transition: background 0.3s ease-in-out;
}

.toggle-circle {
    top: 0.2rem;
    left: 0.25rem;
    transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle-circle {
    transform: translateX(100%);
}

input:checked ~ .toggle-path {
    background-color:#81E6D9;
}
